import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { ButtonProps } from '@shared/components/button';
import { Dialog } from '@shared/components/dialog';

import styles from './Confirmation.styles';

export type ButtonsProps = {
  confirm: Partial<ButtonProps>;
  cancel: Partial<ButtonProps>;
};

export interface ConfirmationProps extends WithStyles<typeof styles> {
  buttonProps?: ButtonsProps;
}

interface ConfirmationState {
  open: boolean;
  withCloseButton?: boolean;
  title: React.ReactNode;
  question: React.ReactNode;
  buttonProps: ButtonsProps;
  callback?: (isConfirmed?: boolean) => void;
}

let showConfirmationFn: any;

export function showConfirmation(
  {
    withCloseButton,
    title,
    question,
    buttonProps,
  }: {
    withCloseButton?: ConfirmationState['withCloseButton'];
    title: ConfirmationState['title'];
    question: ConfirmationState['question'];
    buttonProps?: ButtonsProps;
  },
  callback: (isConfirmed: boolean) => void
): void {
  showConfirmationFn({ title, question, buttonProps, withCloseButton }, callback);
}

const DEFAULT_STATE: ConfirmationState = {
  open: false,
  withCloseButton: true,
  title: '',
  question: '',
  buttonProps: {
    confirm: {
      text: 'Yes',
    },
    cancel: {
      text: 'No',
    },
  },
  callback: undefined,
};

class ConfirmationComponent extends React.Component<ConfirmationProps, ConfirmationState> {
  readonly state = DEFAULT_STATE;

  componentDidMount() {
    showConfirmationFn = this.show;
  }

  private show = (
    {
      title,
      question,
      buttonProps,
      withCloseButton,
    }: {
      title: ConfirmationState['title'];
      question: ConfirmationState['question'];
      buttonProps?: ButtonsProps;
      withCloseButton?: ConfirmationState['withCloseButton'];
    },
    callback: (isConfirmed?: boolean) => void
  ): void => {
    this.setState((state) => ({
      title,
      question,
      callback,
      withCloseButton,
      open: true,
      buttonProps: { ...state.buttonProps, ...buttonProps },
    }));
  };

  private terminate = () => {
    if (this.state.callback) {
      this.state.callback(false);
    }

    this.hide();
  };

  private hide = () => {
    this.setState(
      (state) => ({ ...state, open: false }),
      () => {
        const timeToHideConfirmation = 500;

        setTimeout(() => this.setState(DEFAULT_STATE), timeToHideConfirmation);
      }
    );
  };

  private handleYes = () => {
    if (this.state.callback) {
      this.state.callback(true);
    }

    this.hide();
  };

  render() {
    const { classes } = this.props;
    const { open, title, question, buttonProps, withCloseButton } = this.state;

    return (
      <Dialog
        maxWidth="sm"
        onClose={this.terminate}
        onSubmit={this.handleYes}
        open={open}
        buttonProps={{
          cancel: { ...buttonProps.cancel },
          confirm: { ...buttonProps.confirm },
        }}
        classes={{
          root: classes.root,
          actions: classes.actions,
        }}
        headingProps={{
          heading: title,
          withCloseButton: withCloseButton,
        }}
      >
        <div className={classes.questionContainer}>
          <p className={classes.question}>{question}</p>
        </div>
      </Dialog>
    );
  }
}

export const Confirmation = withStyles(styles)(ConfirmationComponent);
