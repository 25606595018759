import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

import { SwitchType } from './Switch';
export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    label: {
      margin: spacing(0, 3, 0, 0),
    },
    switchRoot: {},
    switchPrimary: {},
    switchThumb: {},
    switchBase: {},
    switchTrack: {},
    [SwitchType.Default]: {},
    [SwitchType.Square]: {
      '& $switchRoot': {
        width: 40,
        height: 20,
        borderRadius: 4,
        padding: 0,
      },
      '& $switchPrimary': {
        '&.Mui-checked': {
          color: colors.white.lightest,
        },

        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: colors.kato.navy[950],
          opacity: 1,
          borderRadius: 4,
        },
      },
      '& $switchBase': {
        padding: '3px !important',
      },
      '& $switchThumb': {
        width: 14,
        height: 14,
        borderRadius: 4,
      },
      '& $switchTrack': {
        backgroundColor: colors.grey.lightest,
        opacity: 1,
        borderRadius: 4,
      },
    },
    [SwitchType.Round]: {
      '& $switchRoot': {
        width: 40,
        height: 20,
        borderRadius: 12,
        padding: 0,
      },

      '& $switchPrimary': {
        '&.Mui-checked': {
          color: colors.white.lightest,
        },

        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: colors.kato.navy[950],
          opacity: 1,
          borderRadius: 12,
        },
      },
      '& $switchBase': {
        padding: '3px !important',
      },
      '& $switchThumb': {
        width: 14,
        height: 14,
        borderRadius: 12,
      },
      '& $switchTrack': {
        backgroundColor: colors.grey.lightest,
        opacity: 1,
        borderRadius: 12,
      },
    },
  });
