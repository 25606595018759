import cx from 'classnames';
import React, { ReactNode, useMemo } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { FilterCounter } from '@shared/components/filter-counter';
import { Tooltip } from '@shared/components/tooltip';

import { styles } from './Badge.styles';

export enum BADGE_THEME {
  grey = 'grey',
  green = 'green',
  warning = 'warning',
  danger = 'danger',
  purple = 'purple',
  blue = 'blue',
  empty = 'empty',
  deepBlue = 'deepBlue', // TODO: Change after palette updated
  orange = 'orange', // TODO: Change after palette updated
}

export enum BADGE_SIZE {
  default = 'default',
  small = 'small',
}

export interface BadgeProps extends WithStyles<typeof styles> {
  count?: number;
  size?: BADGE_SIZE;
  startIcon?: ReactNode;
  text: string;
  theme?: BADGE_THEME;
  tooltip?: string;
  onClick?: () => void;
}

const BadgeComponent: React.FC<BadgeProps> = ({
  classes,
  count,
  size = BADGE_SIZE.default,
  startIcon,
  text,
  theme = BADGE_THEME.grey,
  tooltip,
  onClick,
}) => {
  const badgeClass = cx(classes.badge, classes[size], classes[theme]);

  const content = useMemo(() => {
    return (
      <>
        {text}
        {typeof count === 'number' && (
          <FilterCounter count={count} classes={{ root: classes.counterRoot, count: classes.counterCount }} />
        )}
      </>
    );
  }, [classes, count, text]);
  const iconContent = useMemo(() => {
    if (!startIcon) {
      return null;
    }

    return startIcon;
  }, []);

  if (tooltip) {
    return (
      <Tooltip placement="top" title={tooltip} classes={{ container: badgeClass, root: classes.root }}>
        <>{content}</>
      </Tooltip>
    );
  }

  return (
    <div onClick={onClick} className={badgeClass}>
      {iconContent}
      {content}
    </div>
  );
};

export const Badge = withStyles(styles)(BadgeComponent);
