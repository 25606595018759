import cx from 'classnames';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { parseQueries } from '@shared/utils/common';

import { styles } from './Navigation.styles';
import { SingleSelectOnChange } from '../select/Select.types';
import { SingleSelect } from '../select/single-select';

export enum TabModes {
  exact = 'exact',
  partial = 'partial',
  partialParams = 'partialParams',
}

export interface TabConfig {
  label: string;
  mode?: TabModes;
  value: string;
}

export interface NavigationProps extends WithStyles<typeof styles> {
  tabsConfig: TabConfig[];
  url: string;
  withPageSelector?: boolean;
  onTabChange?: () => void;
}

const NavigationComponent: FC<NavigationProps> = ({
  classes,
  url,
  tabsConfig,
  withPageSelector = true,
  onTabChange,
}) => {
  const history = useHistory();

  const pagesSelectorConfig = useMemo(
    () =>
      tabsConfig.map(({ label, value }) => ({
        label,
        id: value,
      })),
    [tabsConfig]
  );

  const activeTabs = useMemo(
    () =>
      tabsConfig.filter(({ mode, value }) => {
        const [path, queryString] = value.split('?');

        const urlMatches = history.location.pathname.includes(`${url}${path}`);
        const params = parseQueries(queryString);
        const searchParams = parseQueries(history.location.search.slice(1));

        switch (mode) {
          case TabModes.exact: {
            // console.log('activeTabs. exact: ', value, urlMatches, history.location.search, queryString);
            return urlMatches && (!queryString || history.location.search === `?${queryString}`);
          }

          case TabModes.partialParams: {
            // console.log('activeTabs. partialParams: ', value, urlMatches, params, searchParams);
            return (
              urlMatches &&
              Object.keys(params).every((key) => {
                // console.log('checking partial params: ', key, params[key], searchParams[key]);
                return params[key] === searchParams[key];
              })
            );
          }

          default:
          case TabModes.partial: {
            // console.log('activeTabs. default: ', value, urlMatches, queryString, history.location.search);
            return urlMatches && (!queryString || history.location.search.startsWith(`?${queryString}`));
          }
        }
      }),
    [history.location.pathname, history.location.search, tabsConfig, url]
  );

  const activeTabValue = useMemo(() => {
    if (!activeTabs.length) {
      return tabsConfig[0]?.value || '';
    }

    return activeTabs.reduce((result, tab) => (result.length <= tab.value.length ? tab.value : result), '');
  }, [activeTabs, history.location.search]);

  const handlePageSelectorChange: SingleSelectOnChange = (e, value) => {
    const stringValue = value.toString();
    if (stringValue.startsWith('http')) {
      window.location.href = stringValue;
    } else {
      history.push(`${url}${stringValue}`);
    }
  };

  const handlePreventTabClick = (e: any, { value }: TabConfig) => {
    // Do nothing if the user is trying to cmd + click / ctrl + click
    if (e.ctrlKey || e.metaKey) {
      return;
    }
    e.preventDefault();

    if (value.startsWith('http')) {
      window.location.href = value;
    } else {
      history.push(`${url}${value}`);
      if (onTabChange) {
        onTabChange();
      }
    }
  };

  return (
    <Flex className={classes.root} alignItems="flex-end">
      {withPageSelector && (
        <SingleSelect
          value={activeTabValue}
          defaultValue={activeTabValue}
          options={pagesSelectorConfig}
          onChange={handlePageSelectorChange}
          classes={{ root: classes.pageSelector }}
        />
      )}
      <div className={cx(classes.tabs, { [classes.tabsHide as string]: withPageSelector })}>
        {tabsConfig.map((tab, index) => (
          <a
            href={tab.value}
            key={`${tab.value}__${index}`}
            className={classes.tab}
            onClick={(e) => handlePreventTabClick(e, tab)}
          >
            <div
              className={cx(classes.tabUnderline, {
                [classes.tabUnderlineActive]: tab.value === activeTabValue,
              })}
            />
            <div className={cx(classes.tabLabel, { [classes.tabLabelActive]: tab.value === activeTabValue })}>
              {tab.label}
            </div>
          </a>
        ))}
      </div>
    </Flex>
  );
};

export const Navigation = withStyles(styles)(NavigationComponent);
