import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    header: {
      width: '100%',
      padding: spacing(4),
      backgroundColor: colors.white.lightest,
      fontSize: 22,
    },
    withBorder: {
      borderBottom: '1px solid #EAECF0;', //TODO replace after creating new palette
      zIndex: 2,
    },
    headerContainer: {},
    headerText: {
      width: 'calc(100% - 50px)',
      overflow: 'hidden',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    headerIcon: {
      fontSize: 20,
      color: '#9EA6B3', //TODO replace after creating new palette
    },
    headerBtn: {
      minWidth: 38,
      border: 'none',
    },
    headerBtnSmall: {
      padding: '8px 9px',
    },
    headerActionsContainer: {
      alignItems: 'center',
      gap: 12,
    },
  });
