import { WithStyles, withStyles } from '@material-ui/core/styles';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';

import { Tooltip } from '@shared/components/tooltip';

import styles from './ellipsis.styles';

export interface EllipsisProps extends React.HTMLAttributes<HTMLDivElement>, WithStyles<typeof styles> {
  component?: React.ComponentType;
  maxWidth?: string | number;
  text: string | number | React.ReactNode;
  tooltipText?: string;
  tooltipPlacement?: TooltipProps['placement'];
  width?: string | number;
  withTooltip?: boolean;
}

const EllipsisComponent: React.FC<EllipsisProps> = ({
  classes,
  width,
  maxWidth,
  text,
  tooltipText,
  withTooltip,
  tooltipPlacement,
  ...otherProps
}) => {
  if (withTooltip) {
    return (
      <Tooltip
        arrow
        classes={{
          root: classes.root,
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        containerProps={otherProps}
        placement={tooltipPlacement}
        style={{ width, maxWidth }}
        title={tooltipText || String(text) || ''}
      >
        <>{text}</>
      </Tooltip>
    );
  }

  return (
    <div className={classes.root} style={{ width, maxWidth }} {...otherProps}>
      {text}
    </div>
  );
};

EllipsisComponent.defaultProps = {
  withTooltip: true,
};

export const Ellipsis = withStyles(styles)(EllipsisComponent);
