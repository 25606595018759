import { IndexResponse, ListResponsePagination } from '@shared/types/services';

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const defaultPagination: ListResponsePagination = {
  count: 0,
  current_page: 1,
  from: 0,
  to: 0,
  links: {
    last: '',
    next: '',
  },
  per_page: 0,
  total: 0,
  total_pages: 0,
  last_page: 0,
};

export const defaultIndexResponse: IndexResponse<any> = {
  data: [],
  meta: {
    pagination: defaultPagination,
  },
};
