import cx from 'classnames';
import { useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { FieldWithCard, FieldWithCardProps } from '@shared/components/fields/field-with-card';
import { NumberField } from '@shared/components/number-field';

import { styles } from './NumberFieldWithCard.styles';

type genericProps = Omit<FieldWithCardProps, 'field' | 'setFieldInputClass'>;
export interface NumberFieldWithCardProps extends WithStyles<typeof styles>, genericProps {
  disabled?: boolean;
  error?: boolean;
  inputPlaceholder: string;
  inputValue?: string;
  onInputChange: (value: string) => void;
}

const NumberFieldWithCardComponent: React.FC<NumberFieldWithCardProps> = ({
  classes,
  disabled = false,
  error,
  inputPlaceholder,
  inputValue,
  onInputChange,
  ...otherProps
}) => {
  const [fieldInputClass, setFieldInputClass] = useState<string>('');

  return (
    <FieldWithCard
      classes={{ root: classes.root }}
      {...otherProps}
      field={
        <NumberField
          classes={{ root: classes.field, input: cx(classes.fieldInput, fieldInputClass) }}
          disabled={disabled}
          error={error}
          fullWidth
          placeholder={inputPlaceholder}
          value={inputValue}
          variant="outlined"
          onChange={(e) => onInputChange(e.target.value)}
        />
      }
      setFieldInputClass={setFieldInputClass}
    />
  );
};

export const NumberFieldWithCard = withStyles(styles)(NumberFieldWithCardComponent);
