import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import cx from 'classnames';
import React, { useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { FieldHelperText } from '@shared/components/field-helper-text';
import { Flex } from '@shared/components/flex';
import { DATE_TIME_PICKER_FORMAT, PICKER_DATE_FORMAT } from '@shared/utils/date';

import { styles } from './DatePicker.styles';

export type MaterialDate = MaterialUiPickersDate | string | null;

export interface DatePickerProps extends WithStyles<typeof styles> {
  autoWidth?: boolean;
  dateFormat?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  error?: boolean;
  errorText?: string;
  label?: string;
  placeholder: string;
  showTodayButton?: boolean;
  type?: 'date' | 'dateAndTime';
  value: MaterialDate | Date;
  onChange?: (date: MaterialDate, value?: string | null) => void;
}

const DatePickerComponent: React.FC<DatePickerProps> = ({
  autoWidth = true,
  classes,
  dateFormat,
  disabled = false,
  disableFuture = false,
  disablePast = false,
  error,
  errorText,
  label,
  placeholder,
  showTodayButton = true,
  type = 'date',
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  const handleDateChange = (date: MaterialDate, value?: string | null) => {
    if (onChange) {
      onChange(date, value);
    }
  };

  return (
    <Flex autoWidth={autoWidth} direction="column" classes={{ root: classes.root }}>
      {label && <span className={classes.label}>{label}</span>}
      {type === 'date' ? (
        <Flex direction="column" autoWidth={false}>
          <KeyboardDatePicker
            open={open}
            disabled={disabled}
            margin="none"
            placeholder={placeholder}
            format={dateFormat || PICKER_DATE_FORMAT}
            inputVariant="outlined"
            size="small"
            cancelLabel={t('cancel')}
            clearLabel={t('clear')}
            todayLabel={t('today')}
            error={error}
            clearable={true}
            disablePast={disablePast}
            disableFuture={disableFuture}
            showTodayButton={showTodayButton}
            InputAdornmentProps={{ position: 'start' }}
            value={value}
            variant="inline"
            onChange={handleDateChange}
            onClose={() => setOpen(false)}
            onClick={() => setOpen(true)}
            className={cx(classes.picker, { [classes.pickedDisabled]: disabled })}
          />
          <FieldHelperText error={error} errorText={errorText} />
        </Flex>
      ) : (
        <Flex direction="column" autoWidth={false}>
          <KeyboardDateTimePicker
            open={open}
            disabled={disabled}
            margin="none"
            placeholder={placeholder}
            format={dateFormat || DATE_TIME_PICKER_FORMAT}
            inputVariant="outlined"
            size="small"
            cancelLabel={t('cancel')}
            clearLabel={t('clear')}
            todayLabel={t('today')}
            error={error}
            clearable={true}
            hideTabs={true}
            disablePast={disablePast}
            disableFuture={disableFuture}
            showTodayButton={showTodayButton}
            InputAdornmentProps={{ position: 'start' }}
            value={value}
            variant="inline"
            onChange={handleDateChange}
            onClose={() => setOpen(false)}
            onClick={() => setOpen(true)}
            className={cx(classes.picker, { [classes.pickedDisabled]: disabled })}
          />
          <FieldHelperText error={error} errorText={errorText} />
        </Flex>
      )}
    </Flex>
  );
};

export const DatePicker = withStyles(styles)(DatePickerComponent);
