import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) => {
  return createStyles({
    root: {
      '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
        padding: '0 !important',

        '& textarea': {
          padding: '8px 14px',
        },
      },

      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#B42318 !important', //TODO replace after new palette creating
      },
    },
    rootFullWidth: {
      width: '100%',
    },
    input: {
      backgroundColor: colors.white.lightest,

      '& > div[data-lastpass-icon-root="true"]': {
        // NOTE: Unable to use data-lpignore or autoComplete="off". For now, we will have to a CSS hack to hide the LastPass icon
        display: 'none !important',
      },

      '&.Mui-disabled': {
        backgroundColor: '#F2F4F7', // TODO: Change after palette updated
        boxShadow: 'none',
        color: '#7E8899', // TODO: Change after palette updated
        cursor: 'not-allowed',
        pointerEvents: 'none',
        userSelect: 'none',

        '&.Mui-disabled > input': {
          color: 'currentColor',
        },

        '& > .MuiOutlinedInput-notchedOutline': {
          borderColor: '#D1D6DF', // TODO: Change after palette updated
        },
      },

      '&:hover': {
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#9EA6B3', // TODO: Change after palette updated
        },
      },

      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.blue.dark} !important`,
        borderWidth: `1px !important`,
        boxShadow: '0px 0px 0px 4px rgba(50, 77, 252, 0.1);',
        transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
      },

      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        boxShadow: '0px 0px 0px 4px rgb(217 45 32 / 8%)',
      },
    },
    inputWarning: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#B54708 !important', //TODO replace after new palette creating
      },
    },
    medium: {},
    labelRoot: {
      color: '#101828',
      fontSize: 14,
      fontWeight: 500,
      marginBottom: spacing(2),
    },
    label: {},
    labelError: {
      color: '#B42318 !important', //TODO replace after new palette creating
    },
    helperText: {
      paddingTop: spacing(1),
    },
  });
};
