import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing, breakpoints }: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      top: 30,
      width: 500,

      [breakpoints.down('xs')]: {
        minWidth: 'auto',
        width: '100%',
      },
    },
    titleRoot: {
      borderBottom: `1px solid ${colors.grey.lightest}`,
      padding: 0,
    },
    title: {
      padding: spacing(5, 4, 4),
    },
    heading: {
      fontSize: 20,
      fontWeight: 500,
    },
    closeIcon: {
      color: colors.grey.main,
      padding: 0,
    },
    actions: {
      alignItems: 'center',
      background: colors.white.lightest,
      borderTop: `1px solid ${colors.grey.lightest}`,
      flexDirection: 'column',
      padding: spacing(5, 7, 6),
    },
    actionButtons: {
      width: '100% !important',
    },
    actionsError: {
      color: colors.red.darkish,
      margin: spacing(0, 0, 2, 2),
    },
    actionsButtons: {
      width: '100%',
    },
    cancel: {
      marginRight: spacing(2),
    },
  });
};
