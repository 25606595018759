import { Theme } from '@core/theme/types/main';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing, shape }: Theme) =>
  createStyles({
    field: {
      width: 185,
    },
    fieldInput: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    // FieldWithCard classes
    root: {},
    label: {},
    rowWrap: {},
    rowWrapReverse: {},
    disabled: {},
    cardLabel: {},
  });
