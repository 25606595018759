import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    label: {
      color: '#1D2433', //TODO replace after new palette creating
      marginBottom: spacing(3),
    },
    picker: {
      '& .MuiOutlinedInput-adornedStart': {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.blue.dark} !important`,
        },

        '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `1px solid #B42318 !important`, //TODO replace after new palette creating
        },

        '&:hover': {
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9EA6B3', // TODO: Change after palette updated
          },
        },

        height: 36,
        paddingLeft: 0,
        backgroundColor: colors.white.lightest,
        width: 110,

        '& .MuiInputAdornment-positionStart': {
          marginRight: 0,

          '& .MuiIconButton-root': {
            padding: spacing(1.5),

            '& .MuiIconButton-label': {
              '& .MuiSvgIcon-root': {
                fontSize: 16,
                color: colors.grey.main,
              },
            },
          },
        },
      },

      '&.MuiOutlinedInput-inputAdornedStart': {
        fontSize: 13,
        paddingRight: spacing(1.5),
        paddingBottom: spacing(1.75),
      },

      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.blue.dark} !important`,
      },
    },
    pickedDisabled: {
      borderColor: '#9EA6B3', // TODO: Change after palette updated
      pointerEvents: 'none',
      cursor: 'default',
      opacity: 0.5,

      '& .MuiOutlinedInput-adornedStart': {
        backgroundColor: `#F2F4F7 !important`, // TODO: Change after palette updated
      },
    },
    errorText: {
      color: colors.red.darkish,
      fontSize: 13,
    },
  });
