import { FC, lazy, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom';

import { getBaseUrl } from '@core/base-url';
import { useConfig } from '@core/contexts/ConfigContext';
import MarketplaceMembers from '@modules/marketplace-members/MarketplaceMembers';
import { Viewings } from '@modules/viewings/Viewings';
import { browserEventActions } from '@redux/reducers/browserEvent/browserEventReducer';
import LazyLoad from '@shared/components/lazy-load';
import { getDefaultPath } from '@shared/utils/route';

import ROUTES from '../shared/constants/routes';

const Campaigns = lazy(() => import('@modules/campaigns'));
const CRM = lazy(() => import('@modules/crm'));
const Dashboard = lazy(() => import('@modules/dashboard'));
const Diary = lazy(() => import('@modules/diary'));
const Disposals = lazy(() => import('@modules/disposals'));
const Insights = lazy(() => import('@modules/insights'));
const MarketplaceEvents = lazy(() => import('@modules/marketplace-events'));
const Requirements = lazy(() => import('@modules/requirements'));
const Settings = lazy(() => import('@modules/settings'));
const MarketplaceDashboard = lazy(() => import('@modules/marketplace-dashboard'));
const SocietyDisposals = lazy(() => import('@modules/society-disposals'));
const SocietyInsights = lazy(() => import('@modules/society-insights'));
const StyleGuide = lazy(() => import('@modules/style-guide'));
const Team = lazy(() => import('@modules/team'));
const Transactions = lazy(() => import('@modules/transactions'));
const UpgradeToPro = lazy(() => import('@modules/upgrade-to-pro'));

let lastPage = '';
function sendGaEvent(pathname: string, search: string) {
  const currentPage = `${pathname}${search}`;

  if (currentPage && lastPage !== currentPage) {
    lastPage = currentPage;
    // console.log('sendGaEvent. Sending page_view event: ', currentPage);
    ReactGA.event('page_view', { page_location: currentPage });
  }
}

export const PrivateRoutes: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { appNav, appUrl, appUrlLegacy, featuresEnabled } = useConfig();

  // Send initial page view
  useEffect(() => {
    sendGaEvent(window.location.pathname, window.location.search);
  }, []);

  // Listen for changes on history
  useEffect(() => {
    let sendGaTimeout: number | undefined;

    const unregister = history.listen((location) => {
      if (history.action === 'POP') {
        dispatch(browserEventActions.backForwardBrowserEvent(location.search));
      } else {
        dispatch(browserEventActions.urlBrowserEvent());
      }

      // Submit page views
      clearTimeout(sendGaTimeout);
      sendGaTimeout = setTimeout(() => sendGaEvent(location.pathname, location.search), 100);
    });

    return () => {
      clearTimeout(sendGaTimeout);
      unregister();
    };
  }, [history, dispatch]);

  const campaignsEnabled = useMemo(() => {
    return Boolean(featuresEnabled?.webapp.email_campaigns_template_builder);
  }, [featuresEnabled]);

  const routes = useMemo(() => {
    const module: Array<{
      access: boolean;
      routeProps: MergeTypes<{ component: React.ComponentType<any> }, Omit<RouteProps, 'component'>>;
    }> = [
      {
        access: appNav.organisation_home.visible,
        routeProps: { path: ROUTES.organisation.dashboard, component: Dashboard },
      },
      {
        access: appNav.organisation_disposals.visible,
        routeProps: { path: ROUTES.organisation.disposals.root, component: Disposals },
      },
      {
        access: appNav.organisation_requirements.visible,
        routeProps: { path: ROUTES.organisation.requirements.root, component: Requirements },
      },
      { access: true, routeProps: { path: ROUTES.organisation.diary, component: Diary } },
      { access: true, routeProps: { path: ROUTES.organisation.viewings, component: Viewings } },
      { access: campaignsEnabled, routeProps: { path: ROUTES.organisation.campaigns.root, component: Campaigns } },
      { access: true, routeProps: { path: ROUTES.organisation.settings.root, component: Settings } },
      {
        access: appNav.organisation_transactions.visible,
        routeProps: { path: ROUTES.organisation.transactions.root, component: Transactions },
      },
      { access: appNav.organisation_crm.visible, routeProps: { path: ROUTES.organisation.crm.root, component: CRM } },
      { access: true, routeProps: { path: ROUTES.styleGuide.root, component: StyleGuide } },
      {
        access: appNav.organisation_insights.visible,
        routeProps: {
          path: ROUTES.organisation.insights.root,
          component: Insights,
        },
      },
      {
        access: true,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.dashboard}`,
          component: MarketplaceDashboard,
        },
      },
      {
        access: true,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.members}`,
          component: MarketplaceMembers,
        },
      },
      {
        access: appNav.marketplace_insights.visible,
        routeProps: { path: ROUTES.marketplace.insights.root, component: SocietyInsights },
      },
      {
        access: appNav.marketplace_disposals.visible,
        routeProps: { path: ROUTES.marketplace.disposals, component: SocietyDisposals },
      },
      {
        access: true,
        routeProps: { path: ROUTES.organisation.team.root, component: Team },
      },
      {
        access: true,
        routeProps: { path: ROUTES.upgradeToPro.root, component: UpgradeToPro },
      },
      {
        access: true,
        routeProps: {
          path: ROUTES.defaultRedirect,
          component: () => {
            const defaultUrl = getDefaultPath(appUrl, appNav);
            if (defaultUrl.startsWith(appUrlLegacy)) {
              window.location.href = defaultUrl;
            } else {
              history.push(defaultUrl);
            }
            return null;
          },
        },
      },
      {
        access: true,
        routeProps: {
          path: ROUTES.logOut,
          component: () => {
            window.location.href = `${getBaseUrl()}/logout`;
            return null;
          },
        },
      },
      {
        access: true,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.events}`,
          component: (props: any) => <MarketplaceEvents {...props} />,
        },
      },
    ];

    return module.filter(({ access }) => access).map(({ access, ...otherProps }) => otherProps);
  }, [appNav]);

  return (
    <LazyLoad withFallback={false}>
      <Switch>
        {routes.map(({ routeProps: { component: $component, ...otherRouteProps } }) => (
          <Route key={String(otherRouteProps.path)} component={$component} {...otherRouteProps} />
        ))}
        <Redirect exact from={ROUTES.initial} to={getDefaultPath(appUrl, appNav)} />
        <Redirect to={ROUTES.defaultRedirect} />
      </Switch>
    </LazyLoad>
  );
};
