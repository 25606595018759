import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) => {
  return createStyles({
    root: {},
    rootFullWidth: {
      width: '100%',
    },
    input: {
      backgroundColor: colors.white.lightest,

      '&.Mui-disabled': {
        backgroundColor: colors.grey.lightest,
        boxShadow: 'none',
        cursor: 'not-allowed',
        opacity: 0.5,
        pointerEvents: 'none',
        userSelect: 'none',
      },

      '&:hover': {
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#9EA6B3', // TODO: Change after palette updated
        },
      },

      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px !important',
        boxShadow: '0px 0px 0px 4px rgba(50, 77, 252, 0.1);',
        transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
      },

      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        boxShadow: '0px 0px 0px 4px rgb(217 45 32 / 8%)',
      },

      // '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      //   borderColor: `${colors.grey.main} !important`,
      // },
    },
    medium: {},
    labelRoot: {
      fontSize: 14,
      marginBottom: spacing(2),
      color: colors.black.darker,

      '&.Mui-focused': {
        color: colors.black.darker,
      },
    },
    label: {},
    helperText: {
      paddingTop: spacing(1),
    },
  });
};
