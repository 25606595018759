import {
  IconButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { ButtonsProps } from '@shared/components/confirmation';
import { Flex } from '@shared/components/flex';

import { styles } from './Dialog.styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface DialogHeadingProps extends Omit<MuiDialogTitleProps, 'classes'> {
  withCloseButton?: boolean;
  heading?: React.ReactNode;
  onClose?: () => any;
}

export interface DialogProps extends WithStyles<typeof styles>, Omit<MuiDialogProps, 'classes'> {
  headingProps?: DialogHeadingProps;
  withTransition?: boolean;
  buttonProps?: ButtonsProps;
  actionNode?: React.ReactElement;
  withFooter?: boolean;
  error?: string;
}

const DialogComponent: React.FC<DialogProps> = ({
  classes,
  open,
  headingProps,
  withTransition = false,
  withFooter = true,
  onClose,
  onSubmit,
  error,
  buttonProps,
  actionNode,
  children,
  ...otherProps
}) => {
  
  const handleCancel = () => {
    if (onClose) {
      onClose('', 'backdropClick');
    }
  };

  const handleSubmit = (e: any) => {
    if (onSubmit) {
      onSubmit(e);
    }
  };

  const renderActionButtons = () => {
    if (actionNode) {
      return (
        <MuiDialogActions classes={{ root: classes.actions }}>
          {error && <span className={classes.actionsError}>{error}</span>}
          <Flex justifyContent="space-between" classes={{ root: classes.actionButtons }}>
            {actionNode}
            <div>
              <Button
                text={buttonProps?.cancel?.text || 'Cancel'}
                variant="outlined"
                color="default"
                onClick={handleCancel}
                classes={{ root: classes.cancel }}
                {...buttonProps?.cancel}
              />
              <Button text={t('apply')} onClick={handleSubmit} {...buttonProps?.confirm} />
            </div>
          </Flex>
        </MuiDialogActions>
      );
    }

    return (
      <MuiDialogActions classes={{ root: classes.actions }}>
        {error && <span className={classes.actionsError}>{error}</span>}
        <Flex justifyContent="space-between" classes={{ root: classes.actionButtons }}>
          <Button
            text={buttonProps?.cancel?.text || t('cancel')}
            variant="outlined"
            color="default"
            onClick={handleCancel}
            {...buttonProps?.cancel}
          />
          <Button text={t('apply')} onClick={handleSubmit} {...buttonProps?.confirm} />
        </Flex>
      </MuiDialogActions>
    );
  };

  return (
    <MuiDialog
      {...otherProps}
      open={open}
      {...(withTransition && { TransitionComponent: Transition })}
      onClose={onClose}
      classes={{ paper: classes.root }}
    >
      {headingProps && (
        <MuiDialogTitle disableTypography className={classes.titleRoot}>
          <Flex alignItems="center" justifyContent="space-between" classes={{ root: classes.title }}>
            {headingProps.heading && <h1 className={classes.heading}>{headingProps.heading}</h1>}
            {headingProps.withCloseButton && onClose && (
              <IconButton disableRipple className={classes.closeIcon} onClick={handleCancel}>
                <CloseIcon />
              </IconButton>
            )}
          </Flex>
        </MuiDialogTitle>
      )}

      {children}
      {withFooter && renderActionButtons()}
    </MuiDialog>
  );
};

export const Dialog = withStyles(styles)(DialogComponent);
