import {
  FormLabel,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { FieldRenderProps } from 'react-final-form';

import { FieldHelperText } from '@shared/components/field-helper-text';

import { styles } from './NumberField.styles';

type Classes = WithStyles<typeof styles>;

export interface NumberFieldProps extends Omit<MuiTextFieldProps, 'classes' | 'margin' | 'placeholder'>, Classes {
  errorText?: string;
  infoText?: string;
  label?: React.ReactNode;
  placeholder?: string;
  required?: boolean;
  startIcon?: React.ReactNode;
  step?: number;
  isValid?: (value: string) => boolean;
}

export const NumberFieldAdapter = ({ input, meta, ...rest }: FieldRenderProps<string, any>) => {
  const errorMessage: string = (meta.submitFailed && meta.error) || '';

  return (
    <NumberField
      {...input}
      {...rest}
      error={!!errorMessage}
      errorText={errorMessage}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => input.onChange(event.target.value)}
    />
  );
};

class NumberFieldComponent extends React.Component<NumberFieldProps> {
  static defaultProps = {
    step: 1,
  };

  private get startAdornment() {
    const { startIcon } = this.props;

    if (startIcon) {
      return <InputAdornment position="start">{startIcon}</InputAdornment>;
    }

    return null;
  }

  private get endAdornment() {
    return null;
  }

  private handleWheel = (e: any) => {
    e.target.blur();
  };

  private handleChange = (e: any) => {
    e.persist();

    const { onChange } = this.props;
    const { value } = e.target;

    if (!this.isValidValue(value)) {
      return;
    }

    if (onChange) {
      onChange(e);
    }
  };

  private handleBlur = (e: any) => {
    e.persist();

    const { onBlur } = this.props;
    const { value } = e.target;

    if (!this.isValidValue(value)) {
      return;
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  private isValidValue = (value: string) => {
    const { isValid } = this.props;

    if (!isValid) {
      return true;
    }

    return isValid(value);
  };

  render() {
    const {
      classes,
      className,
      error,
      fullWidth,
      errorText,
      infoText,
      inputProps,
      InputProps,
      isValid,
      label,
      onBlur,
      onChange,
      placeholder,
      required,
      step,
      type,
      ...otherProps
    } = this.props;

    const {
      root: rootClass,
      rootFullWidth: rootFullWidthClass,
      labelRoot: labelRootClass,
      input: inputClass,
      medium: mediumClass,
      label: labelClass,
      helperText: helperTextClass,
      ...otherClasses
    } = classes;

    return (
      <div className={cx(rootClass, { [rootFullWidthClass]: fullWidth }, className)}>
        {label && (
          <FormLabel classes={{ root: cx(labelRootClass, labelClass) }} component="legend" error={error}>
            {label}
          </FormLabel>
        )}
        <MuiTextField
          classes={otherClasses}
          error={error}
          fullWidth={fullWidth}
          placeholder={placeholder || undefined}
          size="small"
          type="number"
          variant="outlined"
          InputProps={{
            classes: { root: inputClass },
            endAdornment: this.endAdornment,
            startAdornment: this.startAdornment,
            ...InputProps,
          }}
          inputProps={{ ...inputProps, step: step }}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onWheel={this.handleWheel}
          {...otherProps}
        />
        <FieldHelperText classes={{ root: helperTextClass }} error={error} errorText={errorText} infoText={infoText} />
      </div>
    );
  }
}

export const NumberField = withStyles(styles)(NumberFieldComponent);
