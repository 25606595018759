import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch';
import cx from 'classnames';
import * as React from 'react';

import { Flex } from '@shared/components/flex';

import { styles } from './Switch.styles';

export enum SwitchType {
  Default = 'default',
  Square = 'square',
  Round = 'round',
}

export interface SwitchProps extends Omit<MuiSwitchProps, 'classes'>, WithStyles<typeof styles> {
  label?: React.ReactNode;
  errorText?: string;
  error?: boolean;
  // switchProps: MuiSwitchProps;
  type?: SwitchType;
}

const SwitchComponent: React.FC<SwitchProps> = ({ classes, label, type, ...switchProps }) => {
  return (
    <Flex alignItems="center" className={cx(classes.root, type ? classes[type] : undefined)}>
      {label && (
        <FormLabel classes={{ root: classes.label }} component="legend">
          {label}
        </FormLabel>
      )}
      <MuiSwitch
        {...switchProps}
        classes={{
          root: classes.switchRoot,
          colorPrimary: classes.switchPrimary,
          thumb: classes.switchThumb,
          switchBase: classes.switchBase,
          track: classes.switchTrack,
        }}
      />
    </Flex>
  );
};

export const Switch = withStyles(styles)(SwitchComponent);
