import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export default ({ breakpoints, spacing }: Theme) =>
  createStyles({
    root: {
      minWidth: 552,
    },
    questionContainer: {
      padding: spacing(4, 7, 7),
    },
    question: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.grey.main,
    },
    actions: {
      justifyContent: 'space-between',
      padding: spacing(5, 7, 6),
      background: colors.grey.body,
    },

    [breakpoints.down('xs')]: {
      root: {
        minWidth: 'auto',
      },
    },
  });
