import { Close as CloseIcon } from '@material-ui/icons';
import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { Flex } from '@shared/components/flex';

import { styles } from './DrawerHeader.styles';

export interface DrawerHeaderProps extends WithStyles<typeof styles> {
  customAction?: ReactNode;
  header?: ReactNode;
  withClose?: boolean;
  onClose: () => void;
}

const DrawerHeaderComponent: FC<DrawerHeaderProps> = ({ classes, customAction, header, withClose = true, onClose }) => {
  const justifyContent = header ? 'space-between' : 'flex-end';

  return (
    <Flex
      autoWidth={false}
      alignItems="center"
      justifyContent="space-between"
      className={cx(classes.header, { [classes.withBorder]: header })}
    >
      <Flex
        autoWidth={false}
        wrap="nowrap"
        justifyContent={justifyContent}
        alignItems="center"
        classes={{ root: classes.headerContainer }}
      >
        <div className={classes.headerText}>{header}</div>
        <Flex classes={{ root: classes.headerActionsContainer }} wrap="nowrap">
          {customAction}
          {withClose && (
            <Button
              variant="outlined"
              size="small"
              color="default"
              text={<CloseIcon className={classes.headerIcon} />}
              onClick={onClose}
              classes={{
                root: classes.headerBtn,
                outlinedSizeSmall: classes.headerBtnSmall,
              }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const DrawerHeader = withStyles(styles)(DrawerHeaderComponent);
