const ROUTES = {
  initial: '/',
  logOut: '/logout',
  defaultRedirect: '/redirect',

  organisation: {
    contacts: '/customer-relations',
    dashboard: '/dashboard',
    diary: '/diary',
    team: {
      root: '/team',
      members: '/members',
    },

    campaigns: {
      root: '/campaigns',
      single_property: '/single-property',
      multi_property: '/multi-property',
      creating: '/creating',
    },

    crm: {
      root: '/crm',
      contacts: '/contacts',
      companies: '/companies',
    },

    disposals: {
      root: '/disposals',
      listDefault: '/disposals/all',
      list: {
        all: '/all',
        instruction_availability: '/instruction-availability',
        market_availability: '/market-availability',
        dev_pipeline: '/dev-pipeline',
        pitching: '/pitching',
      },
      manage: {
        summary: '/summary',
        interest_schedule: '/interest-schedule',
        find_matches: '/find-matches',
        insights: {
          root: '/insights',
          overview: '/overview',
          marketplace_performance: '/marketplace-performance',
        },
        management: '/management',
        transactions: '/transactions',
        availability: '/availability',
        media: '/media',
        marketing: '/marketing',
        dev_pipeline: '/dev-pipeline',
      },
    },

    insights: {
      root: '/insights',
      requirements: '/requirements',
      viewings_old: '/viewings-old',
      viewings: '/viewings',
      sale_transactions: '/sale-transactions',
      letting_transactions: '/letting-transactions',
      disposals: '/disposals',
    },

    requirements: {
      root: '/requirements',
      list: '/list',
      manage: {
        summary: '/summary',
        matches: '/matches',
        find_matches: '/find-matches',
        search_criteria: '/search-criteria',
        management: '/management',
      },
    },

    settings: {
      root: '/settings',
      features: '/features',
      password: '/password',
      profile: '/profile',
      campaign_emails: '/campaign-emails',
    },

    viewings: '/viewings',

    transactions: {
      root: '/transactions',
      lettings: '/lettings',
      letting_manage: {
        root: '/manage',
        summary: '/summary',
        headline: '/headline',
        lease_details: '/lease-details',
        lease_events: '/lease-events',
        media: '/media',
      },
      sales: '/sales',
      sale_manage: {
        root: '/manage',
        summary: '/summary',
        headline: '/headline',
        tenancy_details: '/tenancy-details',
        media: '/media',
      },
      manage: '/manage',
    },
  },

  marketplace: {
    root: '/society',
    dashboard: '/dashboard',
    lettings: '/lettings',
    acquisitions: '/acquisitions',
    disposals: '/society/disposals',
    members: '/members',
    events: '/events',
    preferences: '/preferences',

    insights: {
      root: '/society/insights',
      acquisitions: '/acquisitions',
      disposals: '/disposals',
    },
  },

  styleGuide: {
    root: '/style-guide',
    alerts: '/alerts',
    buttons: '/buttons',
    dropdowns: '/dropdowns',
    forms: '/forms',
    icons: '/icons',
    iconsMigration: '/icons-migration',
    layout: '/layout',
    reports: '/reports',
    skeletonPages: '/skeleton-pages',
    tables: '/tables',
  },

  upgradeToPro: {
    root: '/upgrade-to-pro',
  },
};

export default ROUTES;
