import {
  FormLabel,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { ReactNode } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { FieldHelperText } from '@shared/components/field-helper-text';
import { SearchIcon } from '@shared/icons/search';

import { styles } from './TextField.styles';

type Classes = WithStyles<typeof styles>;

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'classes' | 'margin' | 'placeholder'>, Classes {
  errorText?: string;
  infoText?: string;
  label?: ReactNode;
  placeholder?: string;
  required?: boolean;
  startIcon?: ReactNode;
  warning?: boolean;
  warningText?: string;
  withSearchIcon?: boolean;
  isValid?: (value: string) => boolean;
}

export const TextFieldAdapter = ({ input, meta, ...rest }: FieldRenderProps<string, any>) => {
  const errorMessage: string = (meta.submitFailed && meta.error) || '';

  return (
    <TextField
      {...input}
      {...rest}
      error={!!errorMessage}
      errorText={errorMessage}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => input.onChange(event.target.value)}
    />
  );
};

class TextFieldComponent extends React.Component<TextFieldProps> {
  static defaultProps = {};
  state = {
    focus: this.props.autoFocus,
  };

  private get startAdornment() {
    const { startIcon, withSearchIcon } = this.props;

    if (withSearchIcon) {
      return (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      );
    }

    if (startIcon) {
      return <InputAdornment position="start">{startIcon}</InputAdornment>;
    }

    return null;
  }

  private get maxRows() {
    if (this.props.maxRows) {
      if (this.state.focus || this.props.value) {
        return this.props.maxRows;
      } else {
        return 1;
      }
    }

    return undefined;
  }

  private get minRows() {
    if (this.props.minRows) {
      return this.props.minRows;
    }
    if (this.state.focus || this.props.value) {
      return this.props.maxRows || this.props.minRows;
    }

    return 1;
  }

  private handleChange = (e: any) => {
    e.persist();

    const { onChange } = this.props;
    const { value } = e.target;

    if (!this.isValidValue(value)) {
      return;
    }

    if (onChange) {
      onChange(e);
    }
  };

  private handleBlur = (e: any) => {
    e.persist();

    const { onBlur } = this.props;
    const { value } = e.target;
    this.setState({ focus: false });

    if (!this.isValidValue(value)) {
      return;
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  private handleFocus = () => {
    this.setState({
      focus: true,
    });
  };

  private isValidValue = (value: string) => {
    const { isValid } = this.props;

    if (!isValid) {
      return true;
    }

    return isValid(value);
  };

  render() {
    const {
      classes,
      className,
      error,
      errorText,
      fullWidth,
      infoText = '',
      inputProps,
      InputProps,
      isValid,
      label,
      onBlur,
      onChange,
      placeholder,
      required,
      type,
      withSearchIcon,
      multiline,
      rows,
      minRows,
      autoFocus,
      warning,
      warningText,
      ...otherProps
    } = this.props;

    const {
      root: rootClass,
      inputWarning: inputWarningClass,
      rootFullWidth: rootFullWidthClass,
      labelRoot: labelRootClass,
      input: inputClass,
      medium: mediumClass,
      label: labelClass,
      labelError: labelErrorClass,
      helperText: helperTextClass,
      ...otherClasses
    } = classes;

    return (
      <div className={cx(rootClass, { [inputWarningClass]: warning }, { [rootFullWidthClass]: fullWidth }, className)}>
        {label && (
          <FormLabel
            classes={{ root: cx(labelRootClass, labelClass), error: labelErrorClass }}
            component="legend"
            error={error}
          >
            {label}
          </FormLabel>
        )}
        <MuiTextField
          type={type}
          variant="outlined"
          size="small"
          error={error}
          minRows={this.minRows}
          maxRows={this.maxRows}
          multiline={multiline}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
          classes={otherClasses}
          placeholder={placeholder || undefined}
          InputProps={{
            classes: {
              root: inputClass,
            },
            startAdornment: this.startAdornment,
            ...InputProps,
          }}
          inputProps={{
            ...inputProps,
          }}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          {...otherProps}
        />
        <FieldHelperText
          classes={{ root: helperTextClass }}
          error={error}
          errorText={errorText}
          infoText={infoText}
          warning={warning}
          warningText={warningText}
        />
      </div>
    );
  }
}

export const TextField = withStyles(styles)(TextFieldComponent);
